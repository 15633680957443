import React, { useEffect, useState } from "react";
import Helmet from "../../node_modules/react-helmet/lib/Helmet";
import axios from "../../node_modules/axios/index";
import { useAuth } from "../AuthContext";
import { Link } from "react-router-dom";
import PopUp from "../components/PopUp";

export default function AddChannelScreen(props) {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [errorFindingChannel, setErrorFindingChannel] = useState("");
  const [youtubeChannelUrl, setYoutubeChannelUrl] = useState("");
  const [youtubeChannelName, setYoutubeChannelName] = useState("");
  const [youtubeChannelId, setYoutubeChannelId] = useState("");
  const [youtubeChannelImage, setYoutubeChannelImage] = useState("");

  const [buttonPopup, setButtonPopup] = useState(false);

  const [loadingCreateChannel, setLoadingCreateChannel] = useState(false);

  const { currentUser } = useAuth();

  const addYoutubeChannel = async (e) => {
    setErrorFindingChannel("");
    setYoutubeChannelName("");

    e.preventDefault();
    setLoadingCreateChannel(true);
    try {
      const { data } = await axios.post(
        process.env.REACT_APP_API_ADDRESS +
          "products/add-youtube-channel-database",
        {
          urlQuery: youtubeChannelUrl,
          token: currentUser.auth.currentUser.accessToken,
        }
      );

      const creator = {
        _id: youtubeChannelUrl,
        name: data.channelDetails.name,
      }; // Creating a product object

      setLoadingCreateChannel(false);
      setYoutubeChannelName(data.channelDetails.name);
      setYoutubeChannelImage(data.channelDetails.profileImage);
      setYoutubeChannelId(data._id);
      setYoutubeChannelUrl(""); // Clear the input field
    } catch (error) {
      console.log("error", error.response.data);
      const errorMessage = error?.response?.data
        ? error.response.data
        : "Unknown error occurred";

      // Handle error (e.g., show an error message)
      setLoadingCreateChannel(false);

      if (
        typeof errorMessage === "object" &&
        errorMessage !== null &&
        !Array.isArray(errorMessage)
      ) {
        setErrorFindingChannel(errorMessage.message);
      } else {
        setErrorFindingChannel(errorMessage);
      }
    }
  };

  return (
    <div>
      <noscript> </noscript>
      <div className="block-review">
        <Helmet>
          <script
            async
            src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-7649422839365038"
            crossorigin="anonymous"
          ></script>
          <title>Add a new channel | Favoree</title>
          <meta
            name="description"
            content="Help us improve Favoree with new ideas, content, features and remarks"
          ></meta>

          <link
            rel="canonical"
            href="https://www.favoree.io/submit-content"
          ></link>
        </Helmet>
        <div className="ninetyPercent">
          <div className="space"></div>

          <div className="buttonMenu">
            <img
              className="pictureCoverSubmit"
              src="images/coverSubmitChannel.png"
              alt="Favoree channels"
            ></img>
            <PopUp trigger={buttonPopup} setTrigger={setButtonPopup}></PopUp>
            <div className="form2" id="feedback">
              <div className="verticalAlign">
                <h2>Add missing channels on Favoree</h2>
                <text>
                  <i class="fa-solid fa-triangle-exclamation"></i>
                  {"  "}
                  Channels need to have 100K subscribers or more, content must
                  be in English
                </text>
                <div className="space"></div>

                <div>
                  <input
                    type="text"
                    placeholder="Channel link or @handle here"
                    value={youtubeChannelUrl}
                    onChange={(e) => setYoutubeChannelUrl(e.target.value)}
                    style={{
                      "max-width": "24rem",
                      "margin-right": "1rem",
                      "margin-top": "1rem",
                    }}
                  />
                  {loadingCreateChannel ? (
                    <div>
                      <div className="space"></div>

                      <div className="loading">
                        <i className="fa fa-spinner fa-spin"></i> Importing the
                        channel... this can take up to 1 minute ⌛
                      </div>
                    </div>
                  ) : (
                    <button
                      type="button"
                      onClick={
                        currentUser
                          ? (e) => addYoutubeChannel(e)
                          : () => setButtonPopup(true)
                      }
                      style={{ "margin-top": "1rem" }}
                    >
                      Add channel
                    </button>
                  )}
                </div>

                <div className="space"></div>
                {youtubeChannelName && (
                  <div className="vertical align">
                    <text className="success">
                      Success! The channel "{youtubeChannelName}" as been added
                      to Favoree ✅
                    </text>

                    <div className="space"></div>
                    <Link
                      to={`/channel/${youtubeChannelName
                        .replaceAll(" ", "_")
                        .replaceAll("-", "_")
                        .toLowerCase()}-${youtubeChannelId}`}
                    >
                      <button
                        style={{
                          "background-color": "#0693e3",
                          display: "flex",
                          "align-items": "center",
                        }}
                      >
                        {" "}
                        <i class="fa-solid fa-arrow-right"></i>{" "}
                        <strong style={{ margin: "1rem" }}>
                          Rate and review {youtubeChannelName}{" "}
                        </strong>
                        <img
                          className="smallRound"
                          src={youtubeChannelImage}
                          alt={youtubeChannelName}
                          style={{ "margin-top": "0rem" }}
                        />
                      </button>
                    </Link>
                  </div>
                )}
                {errorFindingChannel && (
                  <text className="error">{errorFindingChannel}</text>
                )}
              </div>
              <div className="space"></div>
              <div className="space"></div>

              <div className="hr-line">
                <hr></hr>
              </div>
              <div className="space"></div>

              <strong>Join us on Discord to submit smaller channels!</strong>
              <div className="space"></div>
              <div className="space"></div>

              <a
                class="buttonLightGrey"
                id="purple"
                href="https://discord.com/invite/zfudkGhxzH"
                target="_blank"
              >
                <i class="fa-brands fa-discord"></i> Join us on Discord
              </a>
              <div className="space"></div>
              <div className="space"></div>
            </div>
          </div>
          <div className="space"></div>
        </div>
      </div>
    </div>
  );
}
